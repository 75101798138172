import React, { useEffect, useState, useRef } from 'react';
import { Form, Button, Table } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import { axiosAdmin, axiosApi } from '../services/axiosService';
import './EditItem.css';

const EditItem = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [item, setItem] = useState(null);
  const [variations, setVariations] = useState([]);
  const [categories, setCategories] = useState([]);
  const [groups, setGroups] = useState([]);
  const [collaborators, setCollaborators] = useState([]);
  const [newImages, setNewImages] = useState([]);
  const [previewImages, setPreviewImages] = useState([]);
  const fileInputRef = useRef(null);

  useEffect(() => {
    // Fetch the item data
    axiosApi.get(`/items/${slug}`)
      .then(response => {
        setItem(response.data.item);
      })
      .catch(error => console.error('Error fetching item:', error));
  
    // Fetch categories
    axiosApi.get('/categories/get/all')
      .then(response => {
        setCategories(response.data.filter(category => category.name !== 'all-items'));
      })
      .catch(error => console.error('Error fetching categories:', error));
  
    // Fetch groups
    axiosApi.get('/items/get/groups')
      .then(response => setGroups(response.data))
      .catch(error => console.error('Error fetching groups:', error));
  
    // Fetch collaborators
    axiosApi.get('/items/get/collaborators')
      .then(response => setCollaborators(response.data))
      .catch(error => console.error('Error fetching collaborators:', error));
  }, [slug]);
  
  // Fetch item variations AFTER we have the item.id
  useEffect(() => {
    if (item?.id) {
      axiosApi.get(`/items/${item.id}/variations`)
        .then(response => {
          setVariations(response.data);
        })
        .catch(error => console.error('Error fetching variations:', error));
    }
  }, [item?.id]);
  

  const handleChange = (field, value) => {
    if (field === 'highlight') {
      // Ensure highlight is a boolean value
      value = Boolean(value); // true or false
    }
    setItem(prev => ({ ...prev, [field]: value }));
  };

  const handleVariationChange = (index, field, value) => {
    const updatedVariations = variations.map((variation, i) => {
      // Ensure we're only modifying the variation at the specified index
      if (i === index) {
        return { ...variation, [field]: value };
      }
      return variation;
    });
  
    setVariations(updatedVariations);
  };
  

  const handleAddVariation = () => {
    setVariations([...variations, { id: null, name: '', variation_price: '', type: 'optional' }]);
  };  

  const handleRemoveVariation = (index) => {
    const variationToRemove = variations[index];
  
    if (variationToRemove.id) { // Only delete if it exists in the database
      axiosAdmin.delete(`/items/variations/${variationToRemove.id}`)
        .then(() => {
          // Remove from state after successful deletion
          setVariations(variations.filter((_, i) => i !== index));
        })
        .catch(error => console.error('Error deleting variation:', error));
    } else {
      // If variation was just added (has no ID), just remove from state
      setVariations(variations.filter((_, i) => i !== index));
    }
  };

  const handleSubmit = () => {
    const formData = new FormData();
  
    // Append fields to FormData, ensuring empty fields are passed as null
    Object.keys(item).forEach(key => {
      let value = item[key];
      // If it's a boolean, convert to 1 or 0
      if (key === 'highlight') {
        value = value ? 1 : 0; // Convert true/false to 1/0
      }
      formData.append(key, value === '' ? null : value);
    });
  
    // Append new images
    newImages.forEach(image => formData.append('images', image));
  
    // Append variations as a JSON string
    formData.append('variations', JSON.stringify(variations));
  
    // Send the request
    axiosAdmin.put(`/items/${item.id}/updateItem`, formData)
      .then(() => navigate('/edit-items'))
      .catch(error => console.error('Error updating item:', error));
  };
  
  
  return item ? (
    <div className="edit-item-container">
      <Button variant="secondary" onClick={() => navigate('/edit-items')} className="back-button">
        Back
      </Button>

      <h2>Edit Item: {item.name}</h2>
      <Table bordered hover>
        <tbody>
          {Object.entries(item).map(([key, value]) => (
            key !== 'id' && key !== 'images' && key !== 'variations' && key !== 'group_name' && key !== 'collaborator_name' && key !== 'category_name' && (
              <tr key={key}>
                <td><strong>{key}</strong></td>
                <td>
                  {key === 'category_id' ? (
                    <Form.Control as="select" value={item.category_id} onChange={(e) => handleChange('category_id', e.target.value)}>
                      <option value="">Select Category</option>
                      {categories.map(category => <option key={category.id} value={category.id}>{category.name}</option>)}
                    </Form.Control>
                  ) : key === 'group_id' ? (
                    <Form.Control as="select" value={item.group_id} onChange={(e) => handleChange('group_id', e.target.value)}>
                      <option value="">Select Group</option>
                      {groups.map(group => <option key={group.id} value={group.id}>{group.name}</option>)}
                    </Form.Control>
                  ) : key === 'collaborator_id' ? (
                    <Form.Control as="select" value={item.collaborator_id} onChange={(e) => handleChange('collaborator_id', e.target.value)}>
                      <option value="">Select Collaborator</option>
                      {collaborators.map(collaborator => <option key={collaborator.id} value={collaborator.id}>{collaborator.name}</option>)}
                    </Form.Control>
                  ) : key === 'description_full' || key === 'specifications' ? (
                    <Form.Control 
                      as="textarea" 
                      rows={5} 
                      value={value === null || value === undefined ? '' : value} 
                      onChange={(e) => handleChange(key, e.target.value)} 
                    />
                  ) : key === 'highlight' ? (
                    <Form.Check
                      type="checkbox"
                      checked={value === true} // Set it to `true` or `false` based on current value
                      onChange={(e) => handleChange('highlight', e.target.checked)} // Store as boolean (true/false)
                      label="Highlight this item"
                    />
                  ) : key === 'stock_status' ? ( // Add this condition for stock_status
                    <Form.Control
                      as="select"
                      value={value || 'available_after_ordering'} // Default value if undefined
                      onChange={(e) => handleChange('stock_status', e.target.value)}
                    >
                      <option value="available_immediately">Available Immediately</option>
                      <option value="available_after_ordering">Available After Ordering</option>
                      <option value="available_3_to_5_days">Available in 3 to 5 Days</option>
                      <option value="available_7_to_10_days">Available in 7 to 10 Days</option>
                      <option value="currently_unavailable">Currently Unavailable</option>
                    </Form.Control>
                  ) : (
                    <Form.Control 
                      type="text" 
                      value={value === null || value === undefined ? '' : value} 
                      onChange={(e) => handleChange(key, e.target.value)} 
                    />
                  )}
                </td>
              </tr>
            )
          ))}

          <tr>
            <td><strong>Images</strong></td>
            <td>
              <Button variant="warning" onClick={() => fileInputRef.current.click()}>
                Edit Item Images
              </Button>
              <input type="file" ref={fileInputRef} style={{ display: 'none' }} multiple onChange={(e) => {
                setNewImages(Array.from(e.target.files));
                setPreviewImages(Array.from(e.target.files).map(file => URL.createObjectURL(file)));
              }} />
              <div className="image-previews">
                {previewImages.map((preview, index) => <img key={index} src={preview} alt="Preview" className="image-preview" />)}
              </div>
            </td>
          </tr>

          {/* Variations Table */}
          <tr>
            <td colSpan="2"><h4>Variations</h4></td>
          </tr>
          {variations.map((variation, index) => (
            <tr key={index}>
              <td>Variation {index + 1}</td>
              <td>
                <Form.Control type="text" placeholder="Name" value={variation.name} onChange={(e) => handleVariationChange(index, 'name', e.target.value)} />
                <Form.Control type="number" placeholder="Price" value={variation.variation_price} onChange={(e) => handleVariationChange(index, 'variation_price', e.target.value)} />
                <Form.Control
                  as="select"
                  value={variation.type} // Ensure this correctly reflects the type
                  onChange={(e) => handleVariationChange(index, 'type', e.target.value)} // Update the state when type changes
                >
                  <option value="optional">Optional</option>
                  <option value="exclusive">Exclusive</option>
                </Form.Control>
                <Button variant="danger" size="sm" onClick={() => handleRemoveVariation(index)}>Remove</Button>
              </td>
            </tr>
          ))}
          <tr>
            <td colSpan="2">
              <Button variant="info" onClick={handleAddVariation}>Add Variation</Button>
            </td>
          </tr>

          <tr>
            <td colSpan="2" className="text-center">
              <Button variant="success" onClick={handleSubmit}>Save Changes</Button>
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  ) : <p>Loading...</p>;
};

export default EditItem;
