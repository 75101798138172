import { useState, useEffect } from "react";
import { axiosAdmin } from "../services/axiosService";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  // Check for valid token on component mount
  useEffect(() => {
    const checkToken = async () => {
      try {
        const response = await axiosAdmin.get("/users/validate-token", { withCredentials: true });
        if (response.status === 200) {
          navigate("/dashboard"); // Redirect to dashboard if token is valid
        }
      } catch (error) {
        console.error("Token validation failed:", error);
        // Token is invalid or expired, stay on the login page
      }
    };

    checkToken();
  }, []); // Empty dependency array ensures this runs only once on mount

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await axiosAdmin.post(
        "/users/login",
        { email, password, rememberMe },
        { withCredentials: true }
      );

      if (response.status === 200) {
        navigate("/dashboard");
      }
    } catch (error) {
      console.error("Login failed:", error);

      if (error.response) {
        setError(error.response.data.message || "An unknown error occurred");
      } else {
        setError("Network error or server failure. Please try again later.");
      }
    }
  };

  return (
    <div>
      <h2>Admin Login</h2>
      {error && <p style={{ color: "red" }}>{error}</p>}
      <form onSubmit={handleLogin}>
        <input
          type="email"
          placeholder="Email"
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="password"
          placeholder="Password"
          onChange={(e) => setPassword(e.target.value)}
        />
        <label>
          <input
            type="checkbox"
            checked={rememberMe}
            onChange={(e) => setRememberMe(e.target.checked)}
          />
          Remember Me
        </label>
        <button type="submit">Login</button>
      </form>
    </div>
  );
};

export default Login;