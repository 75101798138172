// src/pages/ManageStock.js
import React, { useEffect, useState } from "react";
import { Table, Button, Image, Form } from "react-bootstrap";
import { axiosApi, axiosAdmin } from "../services/axiosService";
import { useNavigate } from "react-router-dom";
import "./ManageStock.css"; // Create this CSS file for styling

const ManageStock = () => {
  const [items, setItems] = useState([]);
  const navigate = useNavigate();

  // Hardcoded stock status options
  const stockOptions = [
    "available_immediately",
    "available_after_ordering",
    "available_3_to_5_days",
    "available_7_to_10_days",
    "currently_unavailable",
  ];

  // Fetch items
  useEffect(() => {
    axiosApi
      .get(`${process.env.REACT_APP_API_URL}/items/category/all-items`)
      .then((response) => {
        console.log("Fetched Items:", response.data);
        setItems(response.data.items);
      })
      .catch((error) => {
        console.error("Error fetching items", error);
      });
  }, []);

  // Handle input changes for stock and stock status
  const handleInputChange = (e, itemId, field) => {
    const { value } = e.target;
    setItems((prevItems) =>
      prevItems.map((item) =>
        item.id === itemId ? { ...item, [field]: value } : item
      )
    );
  };

  // Handle saving changes
  const handleSaveChanges = (itemId) => {
    const item = items.find((item) => item.id === itemId);

    // Template for updating stock and stock status (Adjust this later)
    axiosAdmin
      .put(`${process.env.REACT_APP_ADMIN_API_URL}/items/${itemId}/updateStock`, {
        stock: item.stock,
        stock_status: item.stock_status,
      })
      .then((response) => {
        console.log("Stock updated successfully:", response.data);
        alert("Stock updated successfully!");
      })
      .catch((error) => {
        console.error("Error updating stock:", error);
        alert("Error updating stock.");
      });
  };

  // Go back to the dashboard
  const handleBack = () => {
    navigate('/dashboard');
  };

  return (
    <div className="manage-stock-container">
      {/* Back Button */}
      <Button variant="secondary" onClick={handleBack} className="back-button">
        ⬅️ Back
      </Button>
      <h2 className="manage-stock-title">Manage Stock</h2>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Image</th>
            <th>Item Name</th>
            <th>Stock Status</th>
            <th>Stock Quantity</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {items.map((item) => (
            <tr key={item.id}>
              <td>
                <Image
                  src={`https://svkrobotics.com${item.images[0]}`}
                  rounded
                  width={50}
                  height={50}
                />
              </td>
              <td>{item.name}</td>
              <td>
                <Form.Select
                  value={item.stock_status}
                  onChange={(e) =>
                    handleInputChange(e, item.id, "stock_status")
                  }
                >
                  <option value="">Select Status</option>
                  {stockOptions.map((status) => (
                    <option key={status} value={status}>
                      {status.replace(/_/g, " ")}
                    </option>
                  ))}
                </Form.Select>
              </td>
              <td>
                <Form.Control
                  type="number"
                  min="0"
                  value={item.stock}
                  onChange={(e) =>
                    handleInputChange(e, item.id, "stock")
                  }
                />
              </td>
              <td>
                <Button
                  variant="success"
                  onClick={() => handleSaveChanges(item.id)}
                >
                  Save Changes
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default ManageStock;
