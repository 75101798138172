import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Form, Card, InputGroup } from "react-bootstrap";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import { axiosApi, axiosAdmin } from "../services/axiosService"; // Import axios for API calls
import './AddItem.css';

const AddItem = () => {
  const [categories, setCategories] = useState([]);
  const [itemGroups, setItemGroups] = useState([]);
  const [collaboratorList, setCollaboratorList] = useState([]);
  const [categoryId, setcategoryId] = useState("");
  const [groupId, setGroupId] = useState(""); // For select box
  const [collaborator, setCollaborator] = useState(""); // For select box
  const [variations, setVariations] = useState([]);
  const [itemName, setItemName] = useState("");
  const [slug, setSlug] = useState("");
  const [productCode, setProductCode] = useState(null);
  const [hsCode, setHsCode] = useState(null);
  const [stock, setStock] = useState(1);
  const [stockStatus, setStockStatus] = useState("available_after_ordering");
  const [descriptionShort, setDescriptionShort] = useState("");
  const [descriptionFull, setDescriptionFull] = useState("");
  const [specifications, setSpecifications] = useState("");
  const [price, setPrice] = useState(0.0);
  const [weight, setWeight] = useState(0);
  const [highlight, setHighlight] = useState(false);
  const [images, setImages] = useState([]);

  const navigate = useNavigate(); // Initialize useNavigate hook

  useEffect(() => {
    // Fetch categories for the category select box
    const fetchCategories = async () => {
      try {
        const response = await axiosApi.get(`${process.env.REACT_APP_API_URL}/categories/get/all`);
        setCategories(response.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };
    fetchCategories();
  }, []);

  useEffect(() => {
    // Fetch item groups for the category select box
    const fetchItemGroups = async () => {
      try {
        const response = await axiosApi.get(`${process.env.REACT_APP_API_URL}/items/get/groups`);
        setItemGroups(response.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };
    fetchItemGroups();
  }, []);

  useEffect(() => {
    // Fetch item groups for the category select box
    const fetchCollaborators = async () => {
      try {
        const response = await axiosApi.get(`${process.env.REACT_APP_API_URL}/items/get/collaborators`);
        setCollaboratorList(response.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };
    fetchCollaborators();
  }, []);

  // Handles variation adding/removing from item
  const handleVariationAdd = () => {
    setVariations([...variations, { name: "", variation_price: "", type: "optional" }]);
  };
  
  const handleVariationChange = (index, field, value) => {
    const updatedVariations = variations.map((variation, i) =>
      i === index ? { ...variation, [field]: value } : variation
    );
    setVariations(updatedVariations);
  };
  
  const handleVariationRemove = (index) => {
    setVariations(variations.filter((_, i) => i !== index));
  }; 
  

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Get the category, group, and collaborator IDs
    const selectedCategory = categories.find((cat) => cat.id === parseInt(categoryId)) || null;
    const selectedGroup = itemGroups.find((group) => group.id === parseInt(groupId)) || null;
    const selectedCollaborator = collaboratorList.find((col) => col.id === parseInt(collaborator)) || null;
  
    const formData = new FormData();
  
    // Required fields
    formData.append("name", itemName?.trim() || ""); // VARCHAR(100) NOT NULL UNIQUE
    formData.append("slug", slug?.trim() || ""); // VARCHAR(100) NOT NULL UNIQUE
    formData.append("description_short", descriptionShort?.trim() || ""); // VARCHAR(200) NOT NULL
    formData.append("price", price ? parseFloat(price).toFixed(2) : "0.00"); // DECIMAL(10, 2) NOT NULL
    formData.append("weight", weight ? parseFloat(weight).toFixed(3) : "0.000"); // DECIMAL(10, 3) NOT NULL
    formData.append("stock", parseInt(stock, 10) || "0"); // INT NOT NULL
    
    // Optional fields - Append empty string if empty
    formData.append("product_code", productCode?.trim() || ""); // VARCHAR(30) NULL UNIQUE
    formData.append("hs_code", hsCode?.trim() || ""); // VARCHAR(30) NULL
    formData.append("description_full", descriptionFull?.trim() || ""); // TEXT
    formData.append("specifications", specifications?.trim() || ""); // TEXT
    
    // Foreign Keys - Ensure to use IDs
    formData.append("category_id", selectedCategory ? selectedCategory.id : ""); // INT NOT NULL
    formData.append("group_id", selectedGroup ? selectedGroup.id : ""); // INT DEFAULT NULL
    formData.append("collaborator_id", selectedCollaborator ? selectedCollaborator.id : ""); // INT DEFAULT NULL
    
    // ENUM - Default value 'available_after_ordering'
    formData.append("stock_status", stockStatus || "available_after_ordering"); 
    
    // Boolean fields - Use 0 or 1 as MariaDB expects tinyint(1)
    formData.append("highlight", highlight ? "1" : "0"); 
    // formData.append("has_variations", hasVariations ? "1" : "0"); 
  
    // Handle image files (multiple images)
    if (images.length > 0) {
      images.forEach((image) => {
        formData.append('images', image);  // Append each image to the FormData
      });
    }
  
    // Handle Variations
    if (variations.length > 0) {
      formData.append("variations", JSON.stringify(variations));
    }
  
    // Debugging: Log the FormData contents
    console.group("FormData Content:");
    for (let [key, value] of formData.entries()) {
      console.log(`${key}:`, value);
    }
    console.groupEnd();
  
    try {
      // Make the POST request using Axios with FormData
      await axiosAdmin.post("/items/addItem", formData);
  
      alert("Item added successfully!");
      navigate("/dashboard"); // Redirect on success
    } catch (error) {
      console.error("Error adding item:", error);
      alert("Error adding item. Please check the form and try again.");
    }
  };
  

  return (
    <Container className="add-item-container">
      
      <Button
        variant="secondary"
        className="back-button"
        onClick={() => navigate("/dashboard")} // Use navigate to redirect to /dashboard
        style={{ marginBottom: "20px", marginTop: "20px" }}
      >
        Back to Dashboard
      </Button>

      <h1>Add New Item</h1>
      <Form onSubmit={handleSubmit}>
        <Row>
          {/* Item Name */}
          <Col md={6}>
            <Form.Group controlId="itemName">
              <Form.Label>Item Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter item name"
                value={itemName}
                onChange={(e) => setItemName(e.target.value)}
                required
              />
            </Form.Group>
          </Col>

          {/* Slug */}
          <Col md={6}>
            <Form.Group controlId="slug">
              <Form.Label>Slug</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter item slug"
                value={slug}
                onChange={(e) => setSlug(e.target.value)}
                required
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          {/* Product Code */}
          <Col md={6}>
            <Form.Group controlId="productCode">
              <Form.Label>Product Code (SVK)</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter product code"
                value={productCode || ""}  // Fallback to empty string if null or undefined
                onChange={(e) => setProductCode(e.target.value)}
              />
            </Form.Group>
          </Col>

          {/* HS Code */}
          <Col md={6}>
            <Form.Group controlId="hsCode">
              <Form.Label>HS Code</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter HS Code"
                value={hsCode || ""}  // Fallback to empty string if null or undefined
                onChange={(e) => setHsCode(e.target.value)}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          {/* Category Select */}
          <Col md={6}>
            <Form.Group controlId="categoryId">
              <Form.Label>Category</Form.Label>
              <Form.Control
                as="select"
                value={categoryId}
                onChange={(e) => setcategoryId(e.target.value)}
                required
              >
                <option value="">Select a Category</option>
                {categories.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>

          {/* Group ID Select */}
          <Col md={6}>
            <Form.Group controlId="groupId">
              <Form.Label>Group ID</Form.Label>
              <Form.Control
                as="select"
                value={groupId || ""}  // Fallback to empty string if groupId is empty or undefined
                onChange={(e) => setGroupId(e.target.value)}
              >
                <option value="">Select Group ID</option>
                {itemGroups.map((group) => (
                  <option key={group.id} value={group.id}>
                    {group.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          {/* Collaborator Select */}
          <Col md={6}>
            <Form.Group controlId="collaborator">
              <Form.Label>Collaborator</Form.Label>
              <Form.Control
                as="select"
                value={collaborator || ""}  // Fallback to empty string if collaborator is empty or undefined
                onChange={(e) => setCollaborator(e.target.value)}
              >
                <option value="">Select Collaborator</option>
                {collaboratorList.map((collaborator) => (
                  <option key={collaborator.id} value={collaborator.id}>
                    {collaborator.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>

          {/* Stock */}
          <Col md={6}>
            <Form.Group controlId="stock">
              <Form.Label>Stock</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter stock quantity"
                value={stock}
                onChange={(e) => setStock(e.target.value)}
                required
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          {/* Stock Status */}
          <Col md={6}>
            <Form.Group controlId="stockStatus">
              <Form.Label>Stock Status</Form.Label>
              <Form.Control
                as="select"
                value={stockStatus}
                onChange={(e) => setStockStatus(e.target.value)}
              >
                <option value="available_immediately">Available Immediately</option>
                <option value="available_after_ordering">Available After Ordering</option>
                <option value="available_3_to_5_days">Available 3-5 Days</option>
                <option value="available_7_to_10_days">Available 7-10 Days</option>
                <option value="currently_unavailable">Currently Unavailable</option>
              </Form.Control>
            </Form.Group>
          </Col>

          {/* Price */}
          <Col md={6}>
            <Form.Group controlId="price">
              <Form.Label>Price</Form.Label>
              <Form.Control
                type="number"
                step="1"
                placeholder="Enter price"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                required
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          {/* Description */}
          <Col md={12}>
            <Form.Group controlId="descriptionShort">
              <Form.Label>Short Description</Form.Label>
              <Form.Control
                as="textarea"
                placeholder="Enter short description"
                value={descriptionShort}
                onChange={(e) => setDescriptionShort(e.target.value)}
                required
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          {/* Full Description */}
          <Col md={12}>
            <Form.Group controlId="descriptionFull">
              <Form.Label>Full Description</Form.Label>
              <Form.Control
                as="textarea"
                placeholder="Enter full description"
                value={descriptionFull}
                onChange={(e) => setDescriptionFull(e.target.value)}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          {/* Specification */}
          <Col md={12}>
            <Form.Group controlId="specifications">
              <Form.Label>Specifications</Form.Label>
              <Form.Control
                as="textarea"
                placeholder="Enter specifications"
                value={specifications}
                onChange={(e) => setSpecifications(e.target.value)}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          {/* Weight */}
          <Col md={6}>
            <Form.Group controlId="weight">
              <Form.Label>Weight In Grams</Form.Label>
              <Form.Control
                type="number"
                step="0.001"
                placeholder="Enter weight"
                value={weight}
                onChange={(e) => setWeight(e.target.value)}
                required
              />
            </Form.Group>
          </Col>

          {/* Highlight */}
          <Col md={6}>
            <Form.Group controlId="highlight">
              <Form.Check
                type="checkbox"
                label="Highlight this item"
                checked={highlight}
                onChange={(e) => setHighlight(e.target.checked)}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <Button onClick={handleVariationAdd}>Add New Variation</Button>
          </Col>
        </Row>

        {variations.map((variation, index) => (
          <Row key={index} className="variation-row align-items-center">
            <Col md={12}>
              <Form.Label className="fw-bold">Variation {index + 1}</Form.Label>
            </Col>

            <Col md={4}>
              <Form.Group>
                <Form.Label>Variation Name</Form.Label>
                <Form.Control
                  type="text"
                  value={variation.name}
                  onChange={(e) => handleVariationChange(index, "name", e.target.value)}
                  placeholder="Enter variation name"
                />
              </Form.Group>
            </Col>

            <Col md={3}>
              <Form.Group>
                <Form.Label>Price</Form.Label>
                <Form.Control
                  type="number"
                  step="0.01"
                  value={variation.price}
                  onChange={(e) => handleVariationChange(index, "price", e.target.value)}
                  placeholder="Enter price"
                />
              </Form.Group>
            </Col>

            <Col md={3}>
              <Form.Group>
                <Form.Label>Type</Form.Label>
                <Form.Control
                  as="select"
                  value={variation.type}  // This should reflect the current "type" value of the variation
                  onChange={(e) => handleVariationChange(index, "type", e.target.value)}
                >
                  <option value="optional">Optional</option>
                  <option value="exclusive">Exclusive</option>
                </Form.Control>
              </Form.Group>
            </Col>

            <Col md={2} className="text-end">
              <Button variant="danger" onClick={() => handleVariationRemove(index)}>❌</Button>
            </Col>
          </Row>
        ))}


        <Row>
          {/* File Upload for Item Image */}
          <Col md={12}>
            <Form.Group controlId="itemImage">
              <Form.Label>Item Images</Form.Label>
              <Form.Control
                type="file"
                multiple  // Allow multiple file selection
                onChange={(e) => setImages([...e.target.files])}
              />
            </Form.Group>
          </Col>
        </Row>

        {/* Item Images Preview */}
        <Row>
          <Col md={12}>
            <div className="image-preview">
              {images.map((img, index) => (
                <img
                  key={index}
                  src={URL.createObjectURL(img)}
                  alt={`Preview ${index}`}
                  className="preview-img"
                  style={{ width: "100px", margin: "10px" }}
                />
              ))}
            </div>
          </Col>
        </Row>

        {/* Submit Button */}
        <Button variant="primary" type="submit" className="mt-3">
          Add Item
        </Button>
      </Form>
    </Container>
  );
};

export default AddItem;
