import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, Form, Table } from "react-bootstrap";
import { axiosApi, axiosAdmin } from "../services/axiosService"; // Import axios for API calls
import { useNavigate } from "react-router-dom"; // Import useNavigate for navigation
import './ManageItemGroups.css';

const ManageItemGroups = () => {
  const [itemGroups, setItemGroups] = useState([]);
  const [newItemGroupName, setNewItemGroupName] = useState("");
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate(); // Initialize useNavigate for navigation

  // Fetch item groups on page load
  const fetchItemGroups = async () => {
    try {
      const response = await axiosApi.get(`${process.env.REACT_APP_API_URL}/items/get/groups`);
      setItemGroups(response.data);
    } catch (error) {
      console.error("Error fetching item groups:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchItemGroups();
  }, []); // Fetch item groups on component mount

  // Handle adding a new item group
  const handleAddItemGroup = async () => {
    if (!newItemGroupName) {
      alert("Item group name is required");
      return;
    }

    try {
      // Send the new item group name via an Axios POST request
      await axiosAdmin.post("/groups/add", { name: newItemGroupName });
      // Re-fetch item groups after adding
      fetchItemGroups();
      setNewItemGroupName(""); // Clear the input after adding
    } catch (error) {
      console.error("Error adding item group:", error);
    }
  };

  // Handle removing an item group
  const handleRemoveItemGroup = async (itemGroupId) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this item group?"
    );

    if (!confirmDelete) {
      return; // If user presses 'Cancel', do nothing
    }

    try {
      await axiosAdmin.delete(`/groups/${itemGroupId}`);
      // Re-fetch item groups after deleting
      fetchItemGroups();
    } catch (error) {
      console.error("Error removing item group:", error);
    }
  };

  // Handle renaming an item group
  const handleRenameItemGroup = async (itemGroupId, newName) => {
    try {
      await axiosAdmin.put(`/groups/${itemGroupId}`, { name: newName });
      // Re-fetch item groups after renaming
      fetchItemGroups();
    } catch (error) {
      console.error("Error renaming item group:", error);
    }
  };

  // Back button click handler
  const handleBackClick = () => {
    navigate("/dashboard"); // Navigate to the dashboard
  };

  return (
    <Container className="manage-item-groups-container">
      {/* Back Button */}
      <Button variant="secondary" onClick={handleBackClick} className="mb-3">
        <i className="bi bi-arrow-left-circle"></i> Back
      </Button>

      <Row>
        {/* Item Groups Table */}
        <Col md={6}>
          <h1>Manage Item Groups</h1>
          {loading ? (
            <div>Loading...</div>
          ) : (
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Item Group Name</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {itemGroups.length > 0 ? (
                  itemGroups.map((itemGroup) => (
                    <tr key={itemGroup.id}>
                      <td>{itemGroup.name}</td>
                      <td>
                        <Button
                          variant="warning"
                          onClick={() => {
                            const newName = prompt("Enter new name:", itemGroup.name);
                            if (newName && newName !== itemGroup.name) {
                              handleRenameItemGroup(itemGroup.id, newName);
                            }
                          }}
                        >
                          Rename
                        </Button>{" "}
                        <Button
                          variant="danger"
                          onClick={() => handleRemoveItemGroup(itemGroup.id)}
                        >
                          Remove
                        </Button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="2">No item groups found.</td>
                  </tr>
                )}
              </tbody>
            </Table>
          )}
        </Col>

        {/* Add New Item Group Form */}
        <Col md={6}>
          <h2>Add New Item Group</h2>
          <div className="add-item-group-form">
            <Form inline>
              <Form.Control
                type="text"
                placeholder="Item group name"
                value={newItemGroupName}
                onChange={(e) => setNewItemGroupName(e.target.value)}
                className="mr-2"
              />
              <Button onClick={handleAddItemGroup} variant="primary">
                Add Item Group
              </Button>
            </Form>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ManageItemGroups;
