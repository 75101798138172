import axios from "axios";

const axiosAdmin = axios.create({
  baseURL: process.env.REACT_APP_ADMIN_API_URL || "https://api.svkrobotics.com/api/admin",
  withCredentials: true,
});

const axiosApi = axios.create({
  baseURL: process.env.REACT_APP_API_URL || "https://api.svkrobotics.com/api",
  withCredentials: false,
});

export { axiosAdmin, axiosApi };
