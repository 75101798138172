import React from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { axiosAdmin } from "../services/axiosService"; // Import axiosAdmin
import "./Dashboard.css"; // Import CSS

const Dashboard = () => {
  // Logout function
  const handleLogout = async () => {
    try {
      await axiosAdmin.post("/users/logout"); // Call the logout endpoint
      window.location.href = "/login"; // Redirect to the login page
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  return (
    <Container className="dashboard-container-fluid">
      {/* Logout Button */}
      <div style={{ textAlign: "right", marginBottom: "20px" }}>
        <Button variant="danger" onClick={handleLogout}>
          Logout
        </Button>
      </div>

      <h1 className="dashboard-title">Admin Dashboard</h1>

      {/* First Row with 3 Cards */}
      <Row className="dashboard-options">
        {/* Add Item */}
        <Col md={4}>
          <Card className="dashboard-card">
            <Card.Body>
              <Card.Title>Add Item</Card.Title>
              <Card.Text>Create a new item for your inventory.</Card.Text>
              <Link to="/add-item">
                <Button>➕ Add Item</Button>
              </Link>
            </Card.Body>
          </Card>
        </Col>

        {/* Edit / Remove Items */}
        <Col md={4}>
          <Card className="dashboard-card">
            <Card.Body>
              <Card.Title>Edit / Remove Items</Card.Title>
              <Card.Text>Modify or delete existing items.</Card.Text>
              <Link to="/edit-items">
                <Button>✏️ Manage Items</Button>
              </Link>
            </Card.Body>
          </Card>
        </Col>

        {/* Set Item Stock */}
        <Col md={4}>
          <Card className="dashboard-card">
            <Card.Body>
              <Card.Title>Set Item's Stock</Card.Title>
              <Card.Text>Update stock quantity for items.</Card.Text>
              <Link to="/stock">
                <Button>📦 Set Stock</Button>
              </Link>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Second Row with 3 Cards */}
      <Row className="dashboard-options mt-4">
        {/* Manage Item Categories */}
        <Col md={4}>
          <Card className="dashboard-card">
            <Card.Body>
              <Card.Title>Manage Item Categories</Card.Title>
              <Card.Text>Manage the categories for your items.</Card.Text>
              <Link to="/categories">
                <Button>🗂️ Manage Categories</Button>
              </Link>
            </Card.Body>
          </Card>
        </Col>

        {/* Manage Item Groups */}
        <Col md={4}>
          <Card className="dashboard-card">
            <Card.Body>
              <Card.Title>Manage Item Groups</Card.Title>
              <Card.Text>Organize items into groups.</Card.Text>
              <Link to="/groups">
                <Button>🔖 Manage Groups</Button>
              </Link>
            </Card.Body>
          </Card>
        </Col>

        {/* Manage Collaborators */}
        <Col md={4}>
          <Card className="dashboard-card">
            <Card.Body>
              <Card.Title>Manage Collaborators</Card.Title>
              <Card.Text>Manage collaborators associated with items.</Card.Text>
              <Link to="/collaborators">
                <Button>🤝 Manage Collaborators</Button>
              </Link>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Third Row with 2 New Cards */}
      <Row className="dashboard-options mt-4">

        {/* Create User */}
        <Col md={4}>
          <Card className="dashboard-card">
            <Card.Body>
              <Card.Title>Create User</Card.Title>
              <Card.Text>Add new users to the system.</Card.Text>
              <Link to="/users">
                <Button>👤 Create User</Button>
              </Link>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Dashboard;