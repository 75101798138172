import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, Form, Table } from "react-bootstrap";
import { axiosApi, axiosAdmin } from "../services/axiosService"; // Import axios for API calls
import { useNavigate } from "react-router-dom"; // Import useNavigate for navigation

import './ManageCategories.css';

const ManageCategories = () => {
  const [categories, setCategories] = useState([]);
  const [categoryName, setCategoryName] = useState("");
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate(); // Initialize useNavigate for navigation

  // Fetch categories on page load
  const fetchCategories = async () => {
    try {
      const response = await axiosApi.get(`${process.env.REACT_APP_API_URL}/categories/get/all`);
      // Exclude 'all-items' category from the list
      setCategories(response.data.filter(category => category.name !== 'all-items')); // Filter out 'all-items'
    } catch (error) {
      console.error("Error fetching categories:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, [loading]); // Fetch categories when loading changes

  // Handle adding a new category
  const handleAddCategory = async () => {
    if (!categoryName) {
      alert("Category name is required");
      return;
    }

    // Convert the category name to lowercase and replace spaces/tabs/other whitespaces with dashes
    const formattedCategoryName = categoryName
      .toLowerCase()
      .replace(/\s+/g, '-') // Replace any whitespace (spaces, tabs, etc.) with a dash
      .replace(/[^a-z0-9-]/g, ''); // Remove any non-alphanumeric characters except dash

    try {
      // Send the formatted category name via an Axios POST request
      await axiosAdmin.post("/category/addCategory", { name: formattedCategoryName });
      // Re-fetch categories after adding
      fetchCategories();
      setCategoryName(""); // Clear the input after adding
    } catch (error) {
      console.error("Error adding category:", error);
    }
  };

  // Handle removing a category
  const handleRemoveCategory = async (categoryId) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this category? All items of this category will also get deleted."
    );

    if (!confirmDelete) {
      return; // If user presses 'Cancel', do nothing
    }

    try {
      await axiosAdmin.delete(`/category/${categoryId}`);
      // Re-fetch categories after deleting
      fetchCategories();
    } catch (error) {
      console.error("Error removing category:", error);
    }
  };

  // Handle renaming a category
  const handleRenameCategory = async (categoryId, newName) => {
    try {
      await axiosAdmin.put(`/category/${categoryId}`, { name: newName });
      // Re-fetch categories after renaming
      fetchCategories();
    } catch (error) {
      console.error("Error renaming category:", error);
    }
  };

  // Back button click handler
  const handleBackClick = () => {
    navigate("/dashboard"); // Navigate to the dashboard
  };

  return (
    <Container className="manage-categories-container">
      {/* Back Button */}
      <Button variant="secondary" onClick={handleBackClick} className="mb-3">
        <i className="bi bi-arrow-left-circle"></i> Back
      </Button>

      <Row>
        {/* Category Table */}
        <Col md={6}>
          <h1>Manage Categories</h1>
          {loading ? (
            <div>Loading...</div>
          ) : (
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Category Name</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {categories.length > 0 ? (
                  categories.map((category) => (
                    <tr key={category.id}>
                      <td>{category.name}</td>
                      <td>
                        <Button
                          variant="warning"
                          onClick={() => {
                            const newName = prompt("Enter new name:", category.name);
                            if (newName && newName !== category.name) {
                              handleRenameCategory(category.id, newName);
                            }
                          }}
                          disabled={category.name === 'all-items'} // Disable if category is 'all-items'
                        >
                          Rename
                        </Button>{" "}
                        <Button
                          variant="danger"
                          onClick={() => handleRemoveCategory(category.id)}
                          disabled={category.name === 'all-items'} // Disable if category is 'all-items'
                        >
                          Remove
                        </Button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="2">No categories found.</td>
                  </tr>
                )}
              </tbody>
            </Table>
          )}
        </Col>

        {/* Add New Category Form */}
        <Col md={6}>
          <h2>Add New Category</h2> {/* Added h2 heading */}
          <div className="add-category-form">
            <Form inline>
              <Form.Control
                type="text"
                placeholder="Category name"
                value={categoryName}
                onChange={(e) => setCategoryName(e.target.value)}
                className="mr-2"
              />
              <Button onClick={handleAddCategory} variant="primary">
                Add Category
              </Button>
            </Form>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ManageCategories;
