import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { axiosAdmin } from "../services/axiosService";

const PrivateRoute = ({ component: Component }) => {
  const [authenticated, setAuthenticated] = useState(null); // Use `null` for initial state to prevent flicker

  const navigate = useNavigate();

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const response = await axiosAdmin.get("/users/dashboard", { withCredentials: true });
        console.log("Dashboard Auth Response:", response.data);
        setAuthenticated(true);
      } catch (error) {
        console.error("Dashboard Auth Failed:", error.response?.data || error.message);
        setAuthenticated(false);
        navigate("/login");
      }
    };
  
    checkAuth();
  }, [navigate]);
  

  // Show nothing while authentication is being checked
  if (authenticated === null) return <p>Loading...</p>;

  return authenticated ? <Component /> : <Navigate to="/login" />;
};

export default PrivateRoute;
