import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Dashboard from "./pages/Dashboard"; // Import the dashboard component
import Login from "./pages/Login"; // Import the login component
import ManageCategories from "./pages/ManageCategories"; // Import Manage Categories page
import AddItem from "./pages/AddItem";
import EditItemList from "./pages/EditItemList";
import EditItem from "./pages/EditItem";
import ManageStock from "./pages/ManageStock";
import ManageItemGroups from "./pages/ManageItemGroups";
import ManageCollaborators from "./pages/ManageCollaborators";
import PrivateRoute from "./routes/PrivateRoute"; // Import PrivateRoute

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Navigate to="/login" />} />

        {/* Private route for dashboard */}
        <Route path="/dashboard" element={<PrivateRoute component={Dashboard} />} />
        
        {/* Private route for Manage Categories */}
        <Route path="/categories" element={<PrivateRoute component={ManageCategories} />} />

        {/* Private route for Add Items */}
        <Route path="/add-item" element={<PrivateRoute component={AddItem} />} />

        {/* Private route for Edit Items List */}
        <Route path="/edit-items" element={<PrivateRoute component={EditItemList} />} />
        {/* Private route for Edit Item */}
        <Route path="/edit-item/:slug" element={<PrivateRoute component={EditItem} />} />

        {/* Private route for Manage Stock */}
        <Route path="/stock" element={<PrivateRoute component={ManageStock} />} />

        {/* Private route for Manage Item Groups */}
        <Route path="/groups" element={<PrivateRoute component={ManageItemGroups} />} />

        {/* Private route for Manage Collaborators */}
        <Route path="/collaborators" element={<PrivateRoute component={ManageCollaborators} />} />

        {/* Private route for Manage Users */}
        {/* <Route path="/users" element={<PrivateRoute component={ManageUsers} />} /> */}
      </Routes>
    </Router>
  );
}

export default App;
