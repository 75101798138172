import React, { useEffect, useState } from "react";
import { Table, Button, Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { axiosApi, axiosAdmin } from "../services/axiosService";
import getImageUrl from "../utils/getImageUrl"; // Import the utility
import "./EditItemList.css"; // Add your CSS here

const EditItemList = () => {
  const [items, setItems] = useState([]);
  const [categories, setCategories] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch items from API
    axiosApi
      .get(`${process.env.REACT_APP_API_URL}/items/category/all-items`)
      .then((response) => {
        console.log("Fetched Items:", response.data); // Log the data to see what is returned
        setItems(response.data.items);
      })
      .catch((error) => {
        console.error("Error fetching items", error);
      });

    // Fetch categories from API
    axiosApi
    .get(`${process.env.REACT_APP_API_URL}/categories/get/all`) // Replace with your actual categories endpoint
    .then((response) => {
      setCategories(response.data); // Assume this is an array of categories
    })
    .catch((error) => {
      console.error("Error fetching categories", error);
    });
  }, []);
  

  const handleDelete = (itemId) => {
    // Send delete request to API
    axiosAdmin
      .delete(`${process.env.REACT_APP_ADMIN_API_URL}/items/${itemId}/deleteItem`) // Replace with your actual delete API endpoint
      .then((response) => {
        // Remove the item from the state after successful deletion
        setItems(items.filter((item) => item.id !== itemId));
      })
      .catch((error) => {
        console.error("Error deleting item", error);
      });
  };

  const handleEdit = (slugName) => {
    // Navigate to the edit page with the slug name
    navigate(`/edit-item/${slugName}`);
  };

  const handleBack = () => {
    navigate('/dashboard');
  };

  const getCategoryName = (categoryId) => {
    const category = categories.find((cat) => cat.id === categoryId);
    return category ? category.name : "Unknown Category"; // Fallback if no category found
  };

  return (
    <div className="edit-item-list-container">
      {/* Back Button */}
      <Button variant="secondary" onClick={handleBack} className="back-button">
        Back
      </Button>
      <h2 className="edit-item-list-title">Edit Items</h2>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Image</th>
            <th>Item Name</th>
            <th>Category</th>
            <th>Stock</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {items.map((item) => (
            <tr key={item.id}>
              <td>
                <Image
                  src={getImageUrl(item.images[0])} // Use the utility function to get the correct image URL
                  rounded
                  width={50}
                  height={50}
                />
              </td>
              <td>{item.name}</td>
              <td>{getCategoryName(item.category_id)}</td> {/* Use the function to get the category name */}
              <td>{item.stock}</td>
              <td>
                <Button
                  variant="primary"
                  onClick={() => handleEdit(item.slug)}
                  className="edit-button"
                >
                  Edit
                </Button>
                <Button
                  variant="danger"
                  onClick={() => handleDelete(item.id)}
                  className="remove-button"
                >
                  Remove
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default EditItemList;
