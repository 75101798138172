import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, Form, Table } from "react-bootstrap";
import { axiosApi, axiosAdmin } from "../services/axiosService"; // Import axios for API calls
import { useNavigate } from "react-router-dom"; // Import useNavigate for navigation
import './ManageCollaborators.css';

const ManageCollaborators = () => {
  const [collaborators, setCollaborators] = useState([]);
  const [newCollaboratorName, setNewCollaboratorName] = useState("");
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate(); // Initialize useNavigate for navigation

  // Fetch collaborators on page load
  const fetchCollaborators = async () => {
    try {
      const response = await axiosApi.get(`${process.env.REACT_APP_API_URL}/items/get/collaborators`);
      setCollaborators(response.data);
    } catch (error) {
      console.error("Error fetching collaborators:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCollaborators();
  }, []); // Fetch collaborators on component mount

  // Handle adding a new collaborator
  const handleAddCollaborator = async () => {
    if (!newCollaboratorName) {
      alert("Collaborator name is required");
      return;
    }

    try {
      // Send the new collaborator name via an Axios POST request
      await axiosAdmin.post("/collaborators/add", { name: newCollaboratorName });
      // Re-fetch collaborators after adding
      fetchCollaborators();
      setNewCollaboratorName(""); // Clear the input after adding
    } catch (error) {
      console.error("Error adding collaborator:", error);
    }
  };

  // Handle removing a collaborator
  const handleRemoveCollaborator = async (collaboratorId) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this collaborator?"
    );

    if (!confirmDelete) {
      return; // If user presses 'Cancel', do nothing
    }

    try {
      await axiosAdmin.delete(`/collaborators/${collaboratorId}`);
      // Re-fetch collaborators after deleting
      fetchCollaborators();
    } catch (error) {
      console.error("Error removing collaborator:", error);
    }
  };

  // Handle renaming a collaborator
  const handleRenameCollaborator = async (collaboratorId, newName) => {
    try {
      await axiosAdmin.put(`/collaborators/${collaboratorId}`, { name: newName });
      // Re-fetch collaborators after renaming
      fetchCollaborators();
    } catch (error) {
      console.error("Error renaming collaborator:", error);
    }
  };

  // Back button click handler
  const handleBackClick = () => {
    navigate("/dashboard"); // Navigate to the dashboard
  };

  return (
    <Container className="manage-collaborators-container">
      {/* Back Button */}
      <Button variant="secondary" onClick={handleBackClick} className="mb-3">
        <i className="bi bi-arrow-left-circle"></i> Back
      </Button>

      <Row>
        {/* Collaborators Table */}
        <Col md={6}>
          <h1>Manage Collaborators</h1>
          {loading ? (
            <div>Loading...</div>
          ) : (
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Collaborator Name</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {collaborators.length > 0 ? (
                  collaborators.map((collaborator) => (
                    <tr key={collaborator.id}>
                      <td>{collaborator.name}</td>
                      <td>
                        <Button
                          variant="warning"
                          onClick={() => {
                            const newName = prompt("Enter new name:", collaborator.name);
                            if (newName && newName !== collaborator.name) {
                              handleRenameCollaborator(collaborator.id, newName);
                            }
                          }}
                        >
                          Rename
                        </Button>{" "}
                        <Button
                          variant="danger"
                          onClick={() => handleRemoveCollaborator(collaborator.id)}
                        >
                          Remove
                        </Button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="2">No collaborators found.</td>
                  </tr>
                )}
              </tbody>
            </Table>
          )}
        </Col>

        {/* Add New Collaborator Form */}
        <Col md={6}>
          <h2>Add New Collaborator</h2>
          <div className="add-collaborator-form">
            <Form inline>
              <Form.Control
                type="text"
                placeholder="Collaborator name"
                value={newCollaboratorName}
                onChange={(e) => setNewCollaboratorName(e.target.value)}
                className="mr-2"
              />
              <Button onClick={handleAddCollaborator} variant="primary">
                Add Collaborator
              </Button>
            </Form>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ManageCollaborators;